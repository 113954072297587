import { RawAxiosRequestHeaders } from 'axios'
import { axiosInstance } from './config'
import { getAuthToken } from './utils'

export const getDiscussions = async () => {
    return axiosInstance.get(`/discussion`)
}

export const postDiscussion = async (discussion: rateauditor.api.discussion.IDiscussionForm) => {
    const headers: RawAxiosRequestHeaders = {}
    const token = getAuthToken({ bearerPrefix: true })
    token && (headers.authorization = token)
    return axiosInstance.post(`/discussion`, discussion, { headers: headers })
}

export const postReply = async (reply: rateauditor.api.discussion.IDiscussionReplyForm, discussionID: String) => {
    const headers: RawAxiosRequestHeaders = {}
    const token = getAuthToken({ bearerPrefix: true })
    token && (headers.authorization = token)
    return axiosInstance.post(`/reply`, [reply, discussionID], { headers: headers })
}

export const deleteDiscussion = async (discussionID: String) => {
    const headers: RawAxiosRequestHeaders = {}
    const token = getAuthToken({ bearerPrefix: true })
    token && (headers.authorization = token)
    return axiosInstance.delete(`/discussion/${discussionID}`, { headers: headers })
}

export const deleteDiscussionReply = async(discussionID: String, replyID: String) => {
    const headers: RawAxiosRequestHeaders = {}
    const token = getAuthToken({ bearerPrefix: true })
    token && (headers.authorization = token)
    return axiosInstance.delete(`/discussion/${discussionID}/reply/${replyID}`, { headers: headers })
}

export const getDiscussionbyUser = async () => {
    const headers: RawAxiosRequestHeaders = {}
    const token = getAuthToken({ bearerPrefix: true })
    token && (headers.authorization = token)
    return axiosInstance.get(`/discussionByUser`, { headers: headers })
}

export const getDiscussionReplyByUser = async () => {
    const headers: RawAxiosRequestHeaders = {}
    const token = getAuthToken({ bearerPrefix: true })
    token && (headers.authorization = token)
    return axiosInstance.get(`/discussionReplyByUser`, { headers: headers })
}
