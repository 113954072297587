import React, { useEffect, useState } from 'react'
import { Row, Col, Alert, Menu, Card } from 'antd'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { SettingOutlined, LockOutlined } from '@ant-design/icons'
import { Helmet } from 'react-helmet-async';

import { reloadUserInfo, selectLoginStatus, selectUserInfo } from '../../../redux/userSlice'
import Loading from '../../components/Loading'
import GeneralTab from './GeneralTab'
import PrivacyTab from './PrivacyTab'

const UserSetting = () => {
  const structuredData = {
    "@context": "https://schema.org",
    "@type": "WebPage",
    "url": "https://www.rateauditor.org/?page=/account/setting",
    "dateModified": "2024-08-13T18:32:23+01:00",
    "priority": "0.6"
  }
  const dispatch = useDispatch()
  const loginStatus = useSelector(selectLoginStatus)
  const userInfo = useSelector(selectUserInfo)

  const [page, setPage] = useState('general')
  const navigate = useNavigate()

  useEffect(() => {
    if (loginStatus === 'success') {
      dispatch(reloadUserInfo())
    }
  }, [])

  useEffect(() => {
    if (loginStatus === 'idle' || loginStatus === 'fail') {
      navigate('/')
    }
  })

  if (loginStatus !== 'success') {
    return <Loading />
  }
  return (
    <>
    <Helmet>
		  <script type="application/ld+json">
        {JSON.stringify(structuredData)}
      </script>	
		</Helmet>
    <Row justify="space-around">
      <Col span={5}>
        <div className="cardContent" style={{ backgroundColor: '#fff' }}>
          <Menu
            defaultSelectedKeys={[page]}
            mode="inline"
            style={{ backgroundColor: 'initial' }}
            onClick={(item) => {
              setPage(item.key)
            }}
          >
            <Menu.Item key="general" icon={<SettingOutlined />}>
              General
            </Menu.Item>
            <Menu.Item key="privacy" icon={<LockOutlined />}>
              Privacy
            </Menu.Item>
          </Menu>
        </div>
      </Col>
      <Col span={18}>
        <Card className="cardContent" bordered={false}>
          {userInfo?.primary_email.is_verified ? (
            <></>
          ) : (
            <Alert type="info" message="You haven't verified your email" showIcon />
          )}

          {(function (page) {
            switch (page) {
              case 'general': {
                return <GeneralTab userInfo={userInfo!} />
              }

              case 'privacy': {
                return <PrivacyTab userInfo={userInfo!} />
              }

              default: {
                // return <Redirect to="/notfind" />
                return <div>nothing here</div>
              }
            }
          })(page)}
        </Card>
      </Col>
    </Row>
    </>
  )
}

export default UserSetting
