import React from 'react'
import { Typography, Card, Button, Col, Row, Divider } from 'antd'
import { Link } from 'react-router-dom'
import './Sitemap.css'
import { useSelector } from 'react-redux'
import { selectUserInfo } from '../../redux/userSlice'


const {Title} = Typography;

const Sitemap = () => {
	const userInfo = useSelector(selectUserInfo)
	console.log(userInfo)
  return (
    <div className="sitemap-container">
			<Card className="sitemap-card" bordered={false}>
				<Row justify="space-around">
					<Col>
						<Title level={2} className="sitemap-title">Sitemap</Title>
					</Col>
				</Row>
      	<Row justify="space-around">
        	<Col xs={10} md={6}>
         		<p className="sitemap-label">General</p>
          	<ul style={{ listStyle: 'none', marginLeft: '0', paddingLeft: '0' }}>
            	<li>
             		<Link className="sitemap-Link" to="/home">Home</Link>
           		</li>
							<li>
								<Link className="sitemap-Link" to="/browse">Browse</Link>
							</li>
							<li>
								<Link className="sitemap-Link" to="/add-auditor">Add Auditor</Link>
							</li>
							<li>
								<Link className="sitemap-Link" to="/discussion-form">Disscusion</Link>
							</li>
          	</ul>
        	</Col>
        	<Col xs={10} md={6}>
          	<p className="sitemap-label">Account</p><ul style={{ listStyle: 'none', marginLeft: '0', paddingLeft: '0' }}>
            	<li>
              	<Link className="sitemap-Link" to="/signup">Signup</Link>
            	</li>
							<li>
              	<Link className="sitemap-Link" to="/login">Login</Link>
            	</li>
							<li>
              	<Link className="sitemap-Link" to="/forgot-password">Forgot Password</Link>
            	</li>
							{userInfo === null ? <></>:
							<li>
              	<Link className="sitemap-Link" to="/account/setting">Account Settings</Link>
            	</li>
							}
          	</ul>
        	</Col>
				</Row>
				<Row justify="space-around">
					<Col xs={10} md={6}>
          	<p className="sitemap-label">Info</p><ul style={{ listStyle: 'none', marginLeft: '0', paddingLeft: '0' }}>
            	<li>
              	<Link className="sitemap-Link" to="/info/aboutus">About Us</Link>
            	</li>
							<li>
								<Link className="sitemap-Link" to="/info/faq">Frequently Asked Questions</Link>
							</li>
							<li>
								<Link className="sitemap-Link" to="/info/terms">Terms and Conditions</Link>
							</li>
							<li>
								<Link className="sitemap-Link" to="/info/privacy">Privacy Policy</Link>
							</li>
							<li>
								<Link className="sitemap-Link" to="/info/contactus">Contact Us</Link>
							</li>
						</ul>
					</Col>
					<Col xs={10} md={6}></Col>
				</Row>
			</Card>
    </div>
	)
}

export default Sitemap
