import React from 'react'
import { Alert, Button, Form, Input } from 'antd'
import { LockOutlined } from '@ant-design/icons'
import { validator as passwordValidator } from '../utils/PasswordValidate'

interface IResetPasswordForm {
  isSubmitting: boolean
  handleResetPassword: (values: any, callback: () => void) => void
  error?: rateauditor.general.IErrorMessage
}

const ResetPasswordForm = (props: IResetPasswordForm) => {
  const { isSubmitting, handleResetPassword, error } = props
  const [form] = Form.useForm()

  const onReset = () => {
    form.resetFields()
  }

  return (
    <Form
      name="resetpassword"
      form={form}
      initialValues={{
        current_password: '',
        password: '',
        confirm: '',
      }}
      onFinish={(value) => {
        handleResetPassword(value, onReset)
      }}
    >
      {error ? (
        <Form.Item>
          <Alert type="error" message={error.message} showIcon />
        </Form.Item>
      ) : (
        <></>
      )}
      <Form.Item
        name="new_password"
        rules={[
            { required: true, message: 'Please input your new password!' },
            { validator: passwordValidator },
        ]}
        hasFeedback
      >
        <Input
          prefix={<LockOutlined className="site-form-item-icon" />}
          type="password"
          placeholder="New Password"
        />
      </Form.Item>

      <Form.Item
        name="confirm"
        dependencies={['new_password']}
        rules={[
          { required: true, message: 'Please confirm your new password!' },
          ({ getFieldValue }) => ({
            validator(rule, value) {
              if (!value || getFieldValue('new_password') === value) {
                return Promise.resolve()
              }
              return Promise.reject(new Error('The two passwords that you entered do not match!'))
            },
          }),
        ]}
        hasFeedback
      >
        <Input
          prefix={<LockOutlined className="site-form-item-icon" />}
          type="password"
          placeholder="Confirm New Password"
        />
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit" loading={isSubmitting}>
          Reset Password
        </Button>
      </Form.Item>
    </Form>
  )
}

export default ResetPasswordForm
