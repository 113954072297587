import React, { SyntheticEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { Input } from 'antd'
import { debounce } from 'lodash-es'

interface IDebounceInputProps {
  value: string
  onChange: (event: React.ChangeEvent) => void
  debounceTimeout?: number
  [key: string]: any
}

function DebounceInput({ value, onChange, debounceTimeout = 800, ...props }: IDebounceInputProps) {
  const [internalValue, setInternalValue] = useState('')

  const handleExternalChange = useCallback(
    (event: React.ChangeEvent) => {
      onChange?.(event)
    },
    [onChange]
  )

  const onExternalChangeDebounced = useMemo(() => {
    return debounce(handleExternalChange, debounceTimeout)
  }, [handleExternalChange, debounceTimeout])

  const onInternalChange = (event: React.ChangeEvent) => {
    const value = (event.target as HTMLInputElement).value
    setInternalValue(value)
    onExternalChangeDebounced(event)
  }

  useEffect(() => {
    if (value !== internalValue) {
      onExternalChangeDebounced.cancel()
      setInternalValue(value)
    }
  }, [value])

  return <Input {...props} value={internalValue} onChange={onInternalChange} />
}

export default DebounceInput
