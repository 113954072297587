import React, { useState } from 'react';
import { sendFeedback } from "@rap/api/es/user";
import { Modal } from 'antd';
import './style.css';

interface FormData {
  name: string;
  email: string;
  message: string;
}

const FeedbackForm: React.FC = () => {
  const [formData, setFormData] = useState<FormData>({
    name: '',
    email: '',
    message: ''
  });

  const [isHovered, setIsHovered] = useState(false);
  const [modal, contextHolder] = Modal.useModal();

  const countDown = () => {
    let secondsToGo = 5;
    const instance = modal.success({
      title: 'Your feedback has been submitted!',
      content: `This alert will be closed in ${secondsToGo} seconds.`,
    });
    const timer = setInterval(() => {
      secondsToGo -= 1;
      instance.update({
        content: `This alert will close after ${secondsToGo} seconds.`,
      });
    }, 1000);
    setTimeout(() => {
      clearInterval(timer);
      instance.destroy();
    }, secondsToGo * 1000);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    sendFeedback(formData).then(() => {
      setFormData({
        name: '',
        email: '',
        message: ''
      });
      countDown();
    });
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <div className="feedback-container">
      <div className="feedback-info">
        <h2>Contact Us</h2>
        <p>
          We value your feedback and inquiries. Whether you have a question, suggestion, or need assistance, our team is here to help. Please feel free to reach out to us via email.
        </p>
        <h3>Get in Touch</h3>
        <p><strong>Email:</strong> <a href="mailto:support@rateauditor.org">support@rateauditor.org</a></p>
        <h3>Why Contact Us?</h3>
        {/* <ul> */}
          <strong>Support:</strong> For any technical issues or questions about using our platform.<br></br>
          <strong>Feedback:</strong> We appreciate your thoughts on how we can improve our services.<br></br>
          <strong>Inquiries:</strong> For any general inquiries or partnership opportunities.<br></br>
        {/* </ul> */}
        <br></br>
        <p>We will try our best to respond to all emails within 24-48 hours. Thank you for connecting with us and for being a part of the RateAuditor community!</p>
      </div>
      <form className="feedback-form" onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="name">Name:</label>
          <input
            type="text"
            id="name"
            name="name"
            placeholder='Enter your name...'
            value={formData.name}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="email">Email:</label>
          <input
            type="email"
            id="email"
            name="email"
            placeholder='Enter your email...'
            value={formData.email}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="message">Message:</label>
          <textarea
            id="message"
            name="message"
            placeholder='Enter your message...'
            value={formData.message}
            onChange={handleChange}
            required
          ></textarea>
        </div>
        <div className='submit-button'>
        <button
          type="submit"
          className={isHovered ? 'button button-hover' : 'button'}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          Submit
        </button>
        {contextHolder}
        </div>
      </form>
    </div>
  );
};

export default FeedbackForm;
