import React, { useEffect, useState }  from 'react'
import ReactPaginate from 'react-paginate';


export interface Comment {
  _id: number;
  author: string;
  title: string;
  content: string;
  tags: string[];
  type: 'note' | 'discussion';
  replies: Comment[];
  admin: boolean;
  timestamp: string;
}

const ForumPosts = ({ posts, updatePosts }: any) => {
	const [visibleReplies, setVisibleReplies] = useState<Set<number>>(new Set()); // Track visible replies
	const handleReplyClick = (commentId: number) => {
    setVisibleReplies(prev => {
      const newReplies = new Set(prev);
      if (newReplies.has(commentId)) {
        newReplies.delete(commentId);
      } else {
        newReplies.add(commentId);
      }
      return newReplies;
    });
  };

	// Data needed for pagination	
	const commentsPerPage = 4;
	const [currentPage, setCurrentPage] = useState<number>(0);
	const offset = currentPage * commentsPerPage;
  const paginatedComments = posts.slice(offset, offset + commentsPerPage);
  const pageCount = Math.ceil(posts.length / commentsPerPage);
	const handlePageChange = (selectedItem: { selected: number }) => {
    setCurrentPage(selectedItem.selected);
  };	

	const renderReplies = (replies: Comment[]) => (
      replies.map(reply => (
          <li key={reply._id} className="reply">
            <strong>{reply.author}</strong>
            { reply.admin && <span className={"admin-badge"}>Admin</span> }
            <p className="reply-content">{reply.content}</p>
            <div className="timestamp">{reply.timestamp}</div> {/* Display timestamp */}
          </li>
      ))
  );


	const renderComments = (comments: Comment[]) => (
      <ul className="comments-list">
        {comments.map(comment => (
            <li key={comment._id} className={`comment ${comment.type}`}>
              <div className="comment-header">
                <div>{comment.type === 'note' ? (
                    <p className="comment-type">Note</p>
                ) : (
                    <p className="comment-type">Discussion</p>
                )}
                </div>
                <div className="author-flag">
                  <strong>{comment.author}</strong>
                  { comment.admin && <span className={"admin-badge"}>Admin</span> }
                </div>
              </div>
              <p><strong>{comment.title}</strong></p>
              <p className="comment-content">{comment.content}</p>
              <div className="tags">
                {comment.tags.map((tag, index) => (
                    <span key={index} className="tag">{tag}</span>
                ))}
              </div>
							<div className="timestamp">{comment.timestamp}</div>
							{comment.replies.length > 0 && comment.type === 'discussion' && (
                  <div className="replies-section">
                    <br></br>
                    <button
                        className="replies-button"
                        onClick={() => handleReplyClick(comment._id)}
                    >
                      {visibleReplies.has(comment._id) ? 'Hide Replies' : 'Show Replies'}
                    </button>
                    {visibleReplies.has(comment._id) && (
                        <ul className="replies-list">
                          {renderReplies(comment.replies)}
                          <br></br>
                        </ul>
                    )}
                  </div>
              )}
            </li>
        ))}
      </ul>
  );

	
	return (<>          
		{paginatedComments.length > 0 ? (
    	<div>{renderComments(paginatedComments)}
    	<div className="pagination-controls">
      	<br></br>
        <ReactPaginate
          previousLabel={'Previous'}
          nextLabel={'Next'}
          breakLabel={'...'}
          breakClassName={'break-me'}
          pageCount={pageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={handlePageChange}
          containerClassName={'pagination'}
          activeClassName={'active'}
        />
      </div></div>
		) : (
    	<p>No Discussions found</p>
    )}
		
	</>)
}

export default ForumPosts
