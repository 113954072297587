import React from 'react'
import { List } from 'antd'
import { Link } from 'react-router-dom'

interface IHomePopularProps {
  popularAuditors: rateauditor.api.auditor.IAuditorInfo[]
}

const HomePopular = (props: IHomePopularProps) => {
  const { popularAuditors } = props
  return (
    <div className="bg-white shadow-md mb-6 p-6">
      <div className="mb-4 text-lg">
        <span className="border-b-4 border-emerald-600">Recent Popular</span>
      </div>
      <List
        dataSource={popularAuditors}
        renderItem={(item) => {
          // const overall_average = item.rate_overall / item.rate_count || 0
          return (
            <List.Item>
              <div>
                <Link to={`/auditor/${item._id}`}>
                  <span>{`${item.first_name} ${item.last_name}`}</span>
                </Link>
              </div>
              {/* <div>
                    <Rate
                      disabled
                      allowHalf
                      defaultValue={0}
                      value={parseFloat(overall_average.toFixed(1))}
                    />
                    <span className="rateNumber">{overall_average.toFixed(1)}</span>
                  </div> */}
            </List.Item>
          )
        }}
      />
    </div>
  )
}

export default HomePopular
