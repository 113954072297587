import React, {useEffect, useState} from 'react'
import { Button, Result } from 'antd'
import {useSelector} from "react-redux";
import {selectUserInfo} from "../../../redux/userSlice";
import {useNavigate} from "react-router-dom";
import {Button as antButton, Table} from "antd";
import {rate as rateApi} from '@rap/api'

interface rateData {
    average: number,
    communication: number,
    comment: string,
    commenter: string,
    created_at: string,
    updated_at: string,
    _id: string,
    knowledge: number,
    quality: number,
    team: number,
    value: number,
    author: {
        username: string,
        permission_group: string[],
    }
    auditor: {
        _id: string,
        first_name: string,
        last_name: string,
        firm_issuing_state: string,
        firm_issuing_country: string,
        firm_issuing_city: string,
        firm_name: string,
    }
}

const CommentVerification = () => {
    const userInfo = useSelector(selectUserInfo)
    const navigate = useNavigate()
    const [ratesList, setRatesList] = useState([])
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

    const onSelectChange = (newSelectedRowKeys: React.Key[], selectedRows: rateData[]) => {
        setSelectedRowKeys(newSelectedRowKeys as string[]);
    };
    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };
    const hasSelected = selectedRowKeys.length > 0;

    const reject = () => {
        for (let i = 0; i < selectedRowKeys.length; i++) {
            const element = selectedRowKeys[i];
            console.log(element.toString())
            rateApi.rejectRate(element.toString()).then((res: any) => {
                location.reload()
            }).catch((err: any) => {
                console.log(err)
            })
        }
        setSelectedRowKeys([]); // initialize selectedRowKeys

    };

    const verify = () => {
        for (let i = 0; i < selectedRowKeys.length; i++) {
            const element = selectedRowKeys[i];
            console.log(element.toString())
            rateApi.verifyRate(element.toString()).then((res: any) => {
                location.reload()
            }).catch((err: any) => {
                console.log(err)
            })
        }
        setSelectedRowKeys([]); // initialize selectedRowKeys


    }

    useEffect(() => {
        if(!userInfo){
            //navigate('/')
            console.log('no user')
        } else if (!userInfo.permission_group.includes('admin')){
           navigate('/')
        }

        rateApi.getPendingRates().then((res: any) => {
            console.log(res.data.rates)
            setRatesList(res.data.rates)
        }).catch((err: any) => {
            console.log(err)
            navigate('/')
        })

        setSelectedRowKeys([]); // initialize selectedRowKeys


    }, []);




    const dataSource: rateData[] = ratesList
    console.log(dataSource[0])

    const columns = [
        Table.EXPAND_COLUMN,
        {
            title: 'Author',
            dataIndex: ['author', 'username'],
            key: 'author',
        },
        {
            title: 'Auditor',
            dataIndex: ['auditor', 'first_name', 'last_name'], // Not used directly but helps for reference
            key: 'auditor',
            render: (text: string, record: rateData) => {
                if (!record.auditor) return 'No auditor assigned';
                return `${record.auditor.first_name} ${record.auditor.last_name}`;
            },
        },
        {
            title: 'Comment',
            dataIndex: 'comment',
            key: 'comment_short',
            width: 120,
            ellipsis: true
        },
        {
            title: 'Average',
            dataIndex: 'average',
            key: 'average',
            sorter: (a: any, b: any) => parseFloat(a.average) - parseFloat(b.average)

        },
        /*{
            title: 'Team',
            dataIndex: 'team',
            key: 'team',
            sorter: (a: any, b: any) => parseFloat(a.team) - parseFloat(b.team)
        },
        {
            title: 'Value',
            dataIndex: 'value',
            key: 'value',
            sorter: (a: any, b: any) => parseFloat(a.value) - parseFloat(b.value)
        },
        {
            title: 'Quality',
            dataIndex: 'quality',
            key: 'quality',
            sorter: (a: any, b: any) => parseFloat(a.quality) - parseFloat(b.quality)
        },
        {
            title: 'Knowledge',
            dataIndex: 'knowledge',
            key: 'knowledge',
            sorter: (a: any, b: any) => parseFloat(a.knowledge) - parseFloat(b.knowledge)
        },
        {
            title: 'Communication',
            dataIndex: 'communication',
            key: 'communication',
            sorter: (a: any, b: any) => parseFloat(a.communication) - parseFloat(b.communication)
        },*/
        {
            title: 'Created At',
            dataIndex: 'created_at',
            key: 'created_at',
            sorter: (a: any, b: any) =>  parseFloat(b.created_at.replace(/-/g, '')) - parseFloat(a.created_at.replace(/-/g, '')),
            defaultSortOrder: 'ascend',
        },
    ];


    return (
        <>
            <div style={{display: 'flex', gap: '8px', marginBottom: '7px'}}>
                <Button type="primary" disabled={!hasSelected} onClick={verify} >
                    Verify
                </Button>
                <Button type="primary" disabled={!hasSelected} onClick={reject}>
                    Reject
                </Button>
            </div>
            <Table dataSource={dataSource} columns={columns} rowSelection={rowSelection}
                   expandable={{
                       expandedRowRender: (record: rateData) => (
                           <div>
                               {record.auditor && (
                                   <>
                                       <p><strong>Auditor Name:</strong> {record.auditor.first_name} {record.auditor.last_name}</p>
                                       <p><strong>Firm Issuing State:</strong> {record.auditor.firm_issuing_state}</p>
                                       <p><strong>Firm Issuing Country:</strong> {record.auditor.firm_issuing_country}
                                       </p>
                                       <p><strong>Firm Issuing City:</strong> {record.auditor.firm_issuing_city}</p>
                                       <p><strong>Firm Name:</strong> {record.auditor.firm_name}</p>
                                   </>
                               )}
                               <p><strong>Average:</strong> {record.average}</p>
                               <p><strong>Team:</strong> {record.team}</p>
                               <p><strong>Value:</strong> {record.value}</p>
                               <p><strong>Quality:</strong> {record.quality}</p>
                               <p><strong>Knowledge:</strong> {record.knowledge}</p>
                               <p><strong>Communication:</strong> {record.communication}</p>
                               <p><strong>Comment:</strong> {record.comment}</p>
                           </div>
                       ),
                   }}
                   rowKey="_id"
                   style={{width: '100%'}}
            />
        </>
    )
}

export default CommentVerification
