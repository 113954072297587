import React from 'react';
import { Helmet } from 'react-helmet-async';


const PrivacyPolicy: React.FC = () => {
  const pdfUrl = 'https://drive.google.com/file/d/14SWdhlBE8PI1vhcKXpHnNizKunNN9LVb/preview';

  const downloadPdf = () => {
    window.open("https://drive.google.com/file/d/14SWdhlBE8PI1vhcKXpHnNizKunNN9LVb/view?usp=sharing", "_blank");
  };

  const structuredData = {
    "@context": "https://schema.org",
    "@type": "WebPage",
    "url": "https://www.rateauditor.org/?page=/privacy-policy",
    "dateModified": "2024-07-19T18:32:27+01:00",
    "priority": "0.8"
  }

  return (
    <>
    <Helmet>
		    <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>	
		  </Helmet>
    <div style={styles.container}>
      <div style={styles.header}>
        <h1 style={styles.title}>Privacy Policy</h1>
        <button style={styles.button} onClick={downloadPdf}>Download PDF</button>
      </div>
      <div style={styles.pdfViewer}>
        <iframe
          src={`https://drive.google.com/file/d/14SWdhlBE8PI1vhcKXpHnNizKunNN9LVb/preview`}
          width="100%"
          height="100%"
          title="Website Privacy Policy"
          style={{ border: 'none' }} // Remove iframe border
        ></iframe>
      </div>
    </div>
    </>
  );
};

// Define CSS styles using JavaScript object notation
const styles: { [key: string]: React.CSSProperties } = {
  container: {
    margin: '10 auto',
    padding: '10px',
    // backgroundColor: '#f0f0f0',
    borderRadius: '8px',
    // boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    textAlign: 'center',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '20px',
  },
  title: {
    fontSize: '24px',
    color: '#333',
    marginRight: '10px', // Space between title and button
  },
  button: {
    backgroundColor: '#059669',
    color: 'white',
    border: 'none',
    padding: '5px 10px',
    fontSize: '16px',
    borderRadius: '4px',
    cursor: 'pointer',
    marginBottom: '10px',
    transition: 'background-color 0.3s ease',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
  },
  pdfViewer: {
    height: '600px',
    overflow: 'hidden', // Hide scrollbar
    marginTop: '20px',
    borderRadius: '8px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    border: '1px solid #f0f0f0',
  },
};

export default PrivacyPolicy;
