import React from 'react'
import { Alert, Button, Col, Row } from 'antd'
import { Link } from 'react-router-dom'

interface ILoginFormProps {
  suffix?: React.ReactNode
}

export const LoginForm = (props: ILoginFormProps) => {
  const { suffix } = props

  return (
    <Row justify="center">
      <p style={{ fontSize: '1.2rem' }}>
        {'Please '}
        <Link to="/login">
          <Button type="dashed">Log In</Button>
        </Link>
        {suffix}
      </p>
    </Row>
  )
}

export const VerifyForm = ({ username }: { username: string }) => {
  return (
    <Row justify="center">
      <Col span={24}>
        <Alert
          type="info"
          message={
            <>
              {"You haven't verified your email"}{' '}
              <Link to={`/user/${username}`}> &gt; go to verify</Link>
            </>
          }
          showIcon
        />
        <div style={{ marginTop: '8px' }} />
      </Col>
    </Row>
  )
}
