import React from 'react'
import { Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'

function Loading() {
  return (
    <div style={{ textAlign: 'center' }}>
      <Spin delay={500} indicator={<LoadingOutlined style={{ fontSize: 100 }} spin />} />
    </div>
  )
}

export default Loading
