import React, { useState } from 'react'
import { Alert, Button, Divider, Form, Input, message } from 'antd'
import { LockOutlined, MailOutlined } from '@ant-design/icons'
import { useDispatch } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet-async';

import { user as userApi } from '@rap/api'
import { login } from '../../redux/userSlice'
import ReCAPTCHA from '../components/ReCAPTCHA'

const Login = () => {
  const structuredData = {
    "@context": "https://schema.org",
    "@type": "WebPage",
    "url": "https://www.rateauditor.org/?page=/login",
    "dateModified": "2024-07-20T18:32:26+01:00",
    "priority": "0.8"
  }
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [isSuccess, setSuccess] = useState(false)
  const [alartLogin, setAlartLogin] = useState<rateauditor.general.IErrorMessage | null>(null)

  const handleLogin = (data: rateauditor.api.user.ILoginData) => {
    userApi.postLogin(data).then((res) => {
      if (res.data.status === 'success') {
        dispatch(login(res.data))
        message.success('Login Success')
        setSuccess(true)
      } else {
        setAlartLogin(res.data.error)
      }
    })
  }

  if (isSuccess) {
    navigate('/')
  }

  return (
    <>
    <Helmet>
    <script type="application/ld+json">
      {JSON.stringify(structuredData)}
    </script>	
  </Helmet>
    <div className="max-w-lg mx-auto bg-white shadow-md p-6">
      <div className="mb-6">
        <h2 className="font-semibold text-3xl mb-1">Welcome Back</h2>
        <p>Sign in to RateAuditor.org</p>
      </div>
      <div>
        <Form name="login" onFinish={handleLogin}>
          {alartLogin && (
            <Form.Item>
              <Alert type="error" message={alartLogin.message} showIcon />
            </Form.Item>
          )}
          <Form.Item
            name="email"
            rules={[
              { required: true, message: 'Please input your email!' },
              { type: 'email', message: 'The input is not valid E-mail!' },
            ]}
          >
            <Input prefix={<MailOutlined className="site-form-item-icon" />} placeholder="Email" />
          </Form.Item>

          <Form.Item
            name="password"
            rules={[{ required: true, message: 'Please input your password!' }]}
          >
            <Input
              prefix={<LockOutlined className="site-form-item-icon" />}
              type="password"
              placeholder="Password"
            />
          </Form.Item>

          <Form.Item
            name="recaptcha"
            rules={[
              {
                validator: (_, value) => {
                  /*if (value === undefined) {
                    return Promise.reject(new Error('Please complete above reCAPTCHA challenge!'))
                  } else if (value === 'error') {
                    return Promise.reject(
                      new Error('Load reCAPTCHA error, please refresh the page and try again.')
                    )
                  } else if (value === 'expire') {
                    return Promise.reject(new Error('reCAPTCHA expire, please try again.'))
                  } else {
                    return Promise.resolve()
                  }*/
                  return Promise.resolve()
                },
              },
            ]}
          >
            <ReCAPTCHA action={'login'} />
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              // loading={props.users.loadingStatus.isLoading}
              htmlType="submit"
              block
            >
              Sign in
            </Button>
          </Form.Item>
        </Form>
      </div>
      <div className="flex justify-between">
        <Link to="/forgot-password">Forgot password?</Link>
        <Divider type="vertical" />
        <Link to="/signup">Create Account</Link>
      </div>
    </div>
    </>
  )
}

export default Login
