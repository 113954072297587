// @ts-nocheck
import React, { useEffect, useState } from 'react'
import { Typography, Col, Row, Alert, Table, TableProps } from 'antd'
import type { ColumnsType } from 'antd/es/table'
import { Link } from 'react-router-dom'
import { SearchOutlined, FrownTwoTone, MehTwoTone, SmileTwoTone } from '@ant-design/icons'
import Highlighter from 'react-highlight-words'
import dayjs from '../../../utils/dayjs'

import styles from './style.module.css'
import { CountryList } from './Location'
import AuditorTableOptions from './AuditorTableOptions'

const { Text } = Typography

interface IRateFaceProps {
  count: number
  rate: number
}

interface IAuditorTableProps {
  isLoading: boolean
  auditorList: rateauditor.api.auditor.IAuditorInfo[]
  searchParams: URLSearchParams
  setSearchParams: any
}

function RateFace(props: IRateFaceProps) {
  if (props.count === 0) {
    return <></>
  } else if (props.rate < 2) {
    return <FrownTwoTone className={styles['rate-face']} twoToneColor="#eb2f96" />
  } else if (props.rate < 4) {
    return <MehTwoTone className={styles['rate-face']} twoToneColor="#ffbf00" />
  } else {
    return <SmileTwoTone className={styles['rate-face']} twoToneColor="#52c41a" />
  }
}

function AuditorTable(props: IAuditorTableProps) {
  const { isLoading, auditorList, searchParams, setSearchParams } = props
  // let sortedInfo = props.sortedInfo || {}
  // const filteredInfo = props.filteredInfo || {}

  const [auditorName, setAuditorName] = useState('')
  const [firmName, setFirmName] = useState('')
  const [location, setLocation] = useState<string[]>([])

  const handleSetAuditorName = (auditorName: string) => {
    setAuditorName(auditorName)
    auditorName.length === 0
      ? searchParams.delete('auditorName')
      : searchParams.set('auditorName', auditorName)
    setSearchParams(searchParams)
  }
  const handleSetFirmName = (firmName: string) => {
    setFirmName(firmName)
    firmName.length === 0 ? searchParams.delete('firmName') : searchParams.set('firmName', firmName)
    setSearchParams(searchParams)
  }
  const handleSetLocation = (location: string[]) => {
    setLocation(location)
    if (location.length === 0) {
      searchParams.delete('country')
      searchParams.delete('state')
    } else if (location.length === 1) {
      searchParams.set('country', location[0])
      searchParams.delete('state')
    } else {
      searchParams.set('country', location[0])
      searchParams.set('state', location[1])
    }
    setSearchParams(searchParams)
  }

  const handleChange: TableProps<rateauditor.api.auditor.IAuditorInfo>['onChange'] = (
    pagination,
    filters,
    sorter
  ) => {
    console.log(filters)
    handleSetAuditorName(filters.name[0])
    handleSetFirmName(filters.firm[0])
    handleSetLocation(filters.location)
  }

  const handleReset = () => {
    handleSetAuditorName('')
    handleSetFirmName('')
    handleSetLocation([])
  }

  /** read url search params on first load */
  useEffect(() => {
    setAuditorName(searchParams.get('auditorName') || '')
    setFirmName(searchParams.get('firmName') || '')
    if (searchParams.has('country')) {
      if (searchParams.has('state')) {
        setLocation([searchParams.get('country')!, searchParams.get('state')!])
      } else {
        setLocation([searchParams.get('country')!])
      }
    } else {
      setLocation([])
    }
  }, [])

  const columns: ColumnsType<rateauditor.api.auditor.IAuditorInfo> = [
    /* eslint-disable react/display-name */
    {
      title: 'Name',
      dataIndex: 'last_name',
      key: 'name',
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
      ),
      filterDropdown: () => <></>,
      filterDropdownVisible: false,
      filteredValue: [auditorName] || null,
      onFilter: (value, record) => {
        const fullname = `${record.first_name || ''} ${record.middle_name || ''} ${
          record.last_name || ''
        }`
        return String(value)
          .toLowerCase()
          .split(/,|\s/)
          .every((element) => {
            return fullname.toLowerCase().includes(element)
          })
      },
      render: (data, row, index) => {
        const name = `${row.last_name || ''}, ${row.first_name || ''} ${row.middle_name || ''}`
        return (
          <Link to={`/auditor/${row._id}`}>
            <Highlighter
              highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
              searchWords={auditorName ? auditorName.split(/,|\s/) : []}
              autoEscape
              textToHighlight={name}
            />
          </Link>
        )
      },
      fixed: 'left',
      width: '20%',
    },
    {
      title: 'Firm',
      dataIndex: 'firm_name',
      key: 'firm',
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
      ),
      filterDropdown: () => <></>,
      filterDropdownVisible: false,
      filteredValue: [firmName] || null,
      onFilter: (value, record) => {
        return String(value)
          .toLowerCase()
          .split(/,|\s/)
          .every((element) => {
            return record.firm_name.toLowerCase().includes(element)
          })
      },
      render: (text, row, index) => {
        return (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={firmName ? firmName.split(/,|\s/) : []}
            autoEscape
            textToHighlight={text}
          />
        )
      },
      width: '20%',
    },
    {
      title: 'Location',
      dataIndex: 'firm_issuing_city',
      key: 'location',
      filters: CountryList,
      filterMultiple: false,
      filteredValue: location.slice(-1) || null,
      filterDropdown: () => <></>,
      filterDropdownVisible: false,
      onFilter: (value, record) => {
        const location = `${record.firm_issuing_country} / ${record.firm_issuing_state} / ${record.firm_issuing_city}`
        return location.toLowerCase().indexOf(value.toLowerCase()) !== -1
      },
      render: (text, row, index) => {
        return (
          <>
            <Text className={styles.location}>{row.firm_issuing_city}</Text>
            <Text
              className={styles.location}
              type="secondary"
            >{`${row.firm_issuing_country} / ${row.firm_issuing_state}`}</Text>
          </>
        )
      },
      width: '20%',
    },
    // {
    //   title: 'Last Rated',
    //   dataIndex: 'last_rate_at',
    //   key: 'lastRated',
    //   // sorter: (a, b) => a.rate_count - b.rate_count,
    //   render: (data, row, index) => {
    //     if (dayjs(data).year() < 2000) {
    //       return <span></span>
    //     }
    //     return <span>{`${dayjs(data).format('M/DD/YYYY')}`}</span>
    //   },
    //   width: '20%',
    // },
    // {
    //   title: '# of Rate',
    //   dataIndex: 'rate_count',
    //   key: 'rateCount',
    //   sorter: (a, b) => a.rate_count - b.rate_count,
    //   render: (data, row, index) => {
    //     return <span>{data}</span>
    //   },
    //   width: '8%',
    // },
    // {
    //   title: 'Rate',
    //   dataIndex: 'rate_overall',
    //   key: 'rate',
    //   sorter: (a, b) => {
    //     const a_avg = a.rate_overall / a.rate_count || 0
    //     const b_avg = b.rate_overall / b.rate_count || 0
    //     return a_avg - b_avg
    //   },
    //   render: (data, row, index) => {
    //     const overall_average = data / row.rate_count || 0
    //     return (
    //       <span>
    //         {row.rate_count === 0 ? (
    //           <span>No rate</span>
    //         ) : (
    //           <span className="rateNumber">{overall_average.toFixed(1)}</span>
    //         )}
    //         &nbsp;
    //         <RateFace rate={overall_average} count={row.rate_count} />
    //       </span>
    //     )
    //   },
    //   fixed: 'right',
    //   width: '8%',
    // },
  ]

  return (
    <div>
      <Row justify="center">
        <Col xs={24} sm={0}>
          <Alert
            message="The current screen size may affect the display of the table, it is recommended to use landscape viewing!"
            type="info"
            showIcon
          />
        </Col>
      </Row>
      <div>
        <AuditorTableOptions
          auditorName={auditorName}
          firmName={firmName}
          location={location}
          setAuditorName={handleSetAuditorName}
          setFirmName={handleSetFirmName}
          setLocation={handleSetLocation}
          handleReset={handleReset}
        />
      </div>
      <div>
        <Table
          columns={columns}
          onChange={handleChange}
          dataSource={auditorList}
          rowKey={(record) => record._id}
          loading={isLoading}
          pagination={{
            defaultPageSize: 10,
            showSizeChanger: false,
            showQuickJumper: true,
            position: ['topRight', 'bottomRight'],
          }}
          size="small"
          scroll={{ x: 'max-content' }}
          bordered
          sticky
        />
      </div>
    </div>
  )
}

export default AuditorTable
