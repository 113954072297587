import React, { useEffect, useState } from 'react';
import { Button, Table } from 'antd';
import { useSelector } from "react-redux";
import { selectUserInfo } from "../../../redux/userSlice";
import { useNavigate } from "react-router-dom";
import {auditor as auditorApi, rate as rateApi} from '@rap/api';
import { user as userApi } from '@rap/api';

export interface auditorData {
    first_name: string,
    last_name: string,
    firm_name: string,
    firm_issuing_state: string,
    firm_issuing_country: string,
    firm_issuing_city: string,
    firm_domain: string,
    profile_claim: string,
    linked_profile: string,
    _id: string,
}

export interface profileData {
    username: string,
    permission_group: string[],
    primary_email: {
        email: string,
        is_verified: boolean,
    }
    basic_profile: {
        first_name: string,
        last_name: string,
    }
}

export interface claimData {
    auditor: auditorData,
    profile: profileData,
}

const AuditorVerification = () => {
    const userInfo = useSelector(selectUserInfo);
    const navigate = useNavigate();
    const [auditorList, setAuditorList] = useState<auditorData[]>([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const [dataSource, setDataSource] = useState<claimData[]>([]);

    const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
        setSelectedRowKeys(newSelectedRowKeys as string[]);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const hasSelected = selectedRowKeys.length > 0;

    const reject = () => {
        for (let i = 0; i < selectedRowKeys.length; i++) {
            const element = selectedRowKeys[i];
            auditorApi.rejectAuditorClaim(element.toString()).then((res: any) => {
                location.reload()
            }).catch((err: any) => {
                console.log(err)
            })
        }
        setSelectedRowKeys([]); // initialize selectedRowKeys
    };

    const verify = () => {
        for (let i = 0; i < selectedRowKeys.length; i++) {
            const element = selectedRowKeys[i];
            auditorApi.verifyAuditorClaim(element.toString()).then((res: any) => {
                location.reload()
            }).catch((err: any) => {
                console.log(err)
            })
        }
        setSelectedRowKeys([]); // initialize selectedRowKeys
    };

    useEffect(() => {
        if (!userInfo) {
            console.log('no user');
        } else if (!userInfo.permission_group.includes('admin')) {
            navigate('/');
        }

        const fetchData = async () => {
            try {
                const res = await auditorApi.getPendingAuditorClaims();
                const pendingAuditors = res.data.auditors.filter((auditor: auditorData) => auditor.profile_claim === 'pending');

                const data: claimData[] = await Promise.all(pendingAuditors.map(async (auditor: auditorData) => {
                    const profileRes = await userApi.getUserById(auditor.linked_profile);
                    return { auditor, profile: profileRes.data.user };
                }));

                setDataSource(data);
            } catch (error) {
                console.error("Error fetching data", error);
            }
        };

        fetchData();
        setSelectedRowKeys([]); // initialize selectedRowKeys
    }, [userInfo, navigate]);

    const columns = [
        Table.EXPAND_COLUMN,
        {
            title: 'Auditor',
            dataIndex: ['auditor', 'first_name'],
            key: 'auditor',
            render: (text: string, record: claimData) => {
                return `${record.auditor.first_name} ${record.auditor.last_name}`;
            },
        },
        {
            title: 'Firm Name',
            dataIndex: ['auditor', 'firm_name'],
            key: 'firm_name',
        },
        {
            title: 'Firm Issuing State',
            dataIndex: ['auditor', 'firm_issuing_state'],
            key: 'firm_issuing_state',
        },
        {
            title: 'Firm Issuing Country',
            dataIndex: ['auditor', 'firm_issuing_country'],
            key: 'firm_issuing_country',
        },
        {
            title: 'Firm Issuing City',
            dataIndex: ['auditor', 'firm_issuing_city'],
            key: 'firm_issuing_city',
        },
        {
            title: 'Firm Domain',
            dataIndex: ['auditor', 'firm_domain'],
            key: 'firm_domain',
        },
        {
            title: 'Linked Profile Name',
            dataIndex: ['profile', 'username'],
            key: 'linked_profile_name',
            render: (text: string, record: claimData) => {
                return `${record.profile.basic_profile.first_name} ${record.profile.basic_profile.last_name}`;
            }
        },
        {
            title: 'Linked Profile username',
            dataIndex: ['profile', 'username'],
            key: 'linked_profile_info',
            render: (text: string, record: claimData) => {
                return record.profile.username || 'N/A';
            }
        },
        {
            title: 'Linked Profile Email',
            dataIndex: ['profile', 'primary_email', 'email'],
            key: 'linked_profile_email',
            render: (text: string, record: claimData) => {
                return record.profile.primary_email.email || 'N/A';
            }
        },
    ];

    return (
        <>
            <div style={{ display: 'flex', gap: '8px', marginBottom: '7px' }}>
                <Button type="primary" disabled={!hasSelected} onClick={verify}>
                    Verify
                </Button>
                <Button type="primary" disabled={!hasSelected} onClick={reject}>
                    Reject
                </Button>
            </div>
            <Table
                dataSource={dataSource}
                columns={columns}
                rowSelection={rowSelection}
                rowKey={record => record.auditor._id}
                style={{ width: '100%' }}
            />
        </>
    );
};

export default AuditorVerification;
