import React, { useEffect, useMemo } from 'react'
import { BrowserRouter } from 'react-router-dom'
import { Helmet } from 'react-helmet-async';
import './App.css'
import Main from './router/Main'

const getBaseName = () => {
  switch (window.location.hostname) {
    case 'preview.rateauditor.org':
      return '/main'
    default:
      return '/'
  }
}

const App = () => {
  const structuredData = {
    "@context": "https://schema.org",
    "@type": "WebSite",
    "url": "https://www.rateauditor.org/",
    "dateModified": "2024-08-13T18:32:23+01:00",
    "priority": "0.6"
  }
  const basename = useMemo(() => {
    return getBaseName()
  }, [])

  return (
    <BrowserRouter basename={basename}>
      <Helmet>
		    <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
		  </Helmet>
      <Main />
    </BrowserRouter>
  )
}

export default App
