import React, { useEffect, useState } from 'react';
import { List, Rate, Row, Col, Input, Button } from 'antd';
import dayjs from '../../../utils/dayjs';
import { rate as rateApi } from '@rap/api';

const { TextArea } = Input;

interface IRateListProps {
  rates?: rateauditor.api.rate.IRateInfo[];
  isLoading: boolean;
  isAuditor?: boolean;
}

interface rateData {
  average: number;
  communication: number;
  comment: string;
  created_at: string;
  updated_at: string;
  _id: string;
  knowledge: number;
  quality: number;
  team: number;
  value: number;
  author: {
    username: string;
    permission_group: string[];
  };
  auditor: {
    _id: string;
    first_name: string;
    last_name: string;
    firm_issuing_state: string;
    firm_issuing_country: string;
    firm_issuing_city: string;
    firm_name: string;
  };
}
const RateList = (props: IRateListProps) => {
  const { rates, isLoading, isAuditor } = props;
  const [replies, setReplies] = useState<{ [key: string]: any[] }>({});
  const [newReplies, setNewReplies] = useState<{ [key: string]: string }>({});

  useEffect(() => {
    if (rates) {
      rates.forEach(async (rate) => {
        try {
          const response = await rateApi.getRateReplies(rate._id);
          setReplies((prevReplies) => ({
            ...prevReplies,
            [rate._id]: response.data.replies,
          }));
        } catch (error) {
          console.error(`Failed to fetch replies for rate ${rate._id}:`, error);
        }
      });
    }
  }, [rates]);

  const handleReplyChange = (id: string, value: string) => {
    setNewReplies((prevReplies) => ({ ...prevReplies, [id]: value }));
  };

  const handleReplySubmit = async (id: string) => {
    if (newReplies[id]) {
      // Submit reply logic here
      try {
        await rateApi.rateReply(id, newReplies[id]);
        setReplies((prevReplies) => ({
          ...prevReplies,
          [id]: [...(prevReplies[id] || []), { comment: newReplies[id], created_at: new Date().toISOString() }],
        }));
        setNewReplies((prevReplies) => ({
          ...prevReplies,
          [id]: '',
        }));
      } catch (error) {
        console.error(`Failed to submit reply for rate ${id}:`, error);
      }
    }
  };

  return (
      <>
        <List
            size="large"
            className="articleList"
            rowKey="_id"
            itemLayout="vertical"
            loading={isLoading}
            dataSource={rates}
            renderItem={(item) => (
                <List.Item key={item._id}>
                  <List.Item.Meta
                      title={
                        <Row>
                          <Col>
                            <Rate disabled allowHalf value={parseFloat(item.average.toFixed(1))} />
                          </Col>
                        </Row>
                      }
                      description={
                        <div className="description">
                          <Row justify="space-between" className="rateDetail">
                            <Col xs={24} sm={5}>{`Knowledge: ${item.knowledge.toFixed(1)}`}</Col>
                            <Col xs={24} sm={6}>{`Communication: ${item.communication.toFixed(1)}`}</Col>
                            <Col xs={24} sm={4}>{`Team: ${item.team.toFixed(1)}`}</Col>
                            <Col xs={24} sm={4}>{`Value: ${item.value.toFixed(1)}`}</Col>
                            <Col xs={24} sm={4}>{`Quality: ${item.quality.toFixed(1)}`}</Col>
                          </Row>
                          <p>{item.comment}</p>
                          <div className="extra">
                            <div className="createdAt">
                              {dayjs(item.updated_at).format('YYYY-MM-DD HH:mm')}
                            </div>
                          </div>
                          {replies[item._id] && replies[item._id].length > 0 && (
                              <div className="replies">
                                <h4 className="auditorReplyTitle">Replies from Auditor:</h4>
                                {replies[item._id].map((reply, index) => (
                                    <div key={index} className="replyItem">
                                      <p>{reply.comment}</p>
                                      <div className="replyMeta">
                                        <span>{dayjs(reply.created_at).format('YYYY-MM-DD HH:mm')}</span>
                                      </div>
                                    </div>
                                ))}
                              </div>
                          )}
                          {isAuditor && (!replies[item._id] || replies[item._id].length === 0) && (
                              <div className="replySection">
                                <TextArea
                                    rows={4}
                                    value={newReplies[item._id] || ''}
                                    onChange={(e) => handleReplyChange(item._id, e.target.value)}
                                    placeholder="Write your reply here"
                                />
                                <Button
                                    type="primary"
                                    onClick={() => handleReplySubmit(item._id)}
                                    style={{ marginTop: '10px' }}
                                >
                                  Submit Reply
                                </Button>
                              </div>
                          )}
                        </div>
                      }
                  />
                </List.Item>
            )}
        />
        <style>{`
        .description {
          color: black;

          .rateDetail {
            margin-bottom: 10px;
          }

          .extra {
            margin-top: 10px;
          }

          .createdAt {
            color: gray;
            font-size: 0.9em;
          }

          .replies {
            margin-top: 15px;
            padding: 10px;
            background: #f9f9f9;
            border: 1px solid #e8e8e8;
            border-radius: 5px;
          }

          .auditorReplyTitle {
            color: gray;
            margin-bottom: 10px;
            font-size: 1.1em;
          }

          .replyItem {
            margin-bottom: 10px;
            padding: 10px;
            background: white;
            border: 1px solid #e8e8e8;
            border-radius: 5px;

            p {
              margin: 0;
              color: black;
            }

            .replyMeta {
              margin-top: 5px;
              text-align: right;
              font-size: 0.85em;
              color: gray;
            }
          }

          .replySection {
            margin-top: 15px;
          }
        }
      `}</style>
      </>
  );
};

export default RateList;
