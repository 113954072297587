 export const validator = (rule: any, value: string): Promise<void> => {
    if (!value) {
        return Promise.resolve()
    } else if (
        value.length < 8 ||
        !value.match(/[a-z]/) ||
        !value.match(/[A-Z]/) ||
        !value.match(/[0-9]/) ||
        !value.match(/\W/)
    ) {
        return Promise.reject(
            new Error(
                'Use 8 or more characters with a mix of uppercase and lowercase letters, numbers & symbols'
            )
        )
    }
    return Promise.resolve()
}
