import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AxiosResponse } from 'axios'
import { utils, user as userApi } from '@rap/api'
import { RootState } from './store'

const { getAuthToken, setAuthToken, delAuthToken } = utils

// idle: User not logged in (possible localstorage is empty or token expired)
type TStatus = 'init' | 'idle' | 'loading' | 'success' | 'fail'

interface ILoginStatus {
  status: TStatus
  showAuthModal: boolean
  info: rateauditor.api.user.IUserInfo | null
}

const defaultState: ILoginStatus = {
  status: 'idle',
  showAuthModal: false,
  info: null,
}

export const restoreLoginStatus = createAsyncThunk('user/restoreLoginStatus', async () => {
  const result = userApi
    .getCurrentUserInfo()
    .then((res) => {
      return {
        status: 'success',
        info: res.data.user,
      }
    })
    .catch(() => {
      return {
        status: 'idle',
        info: defaultState.info,
      }
    })
  return result
})

export const reloadUserInfo = createAsyncThunk('user/reloadUserInfo', async () => {
  const result = userApi
    .getCurrentUserInfo()
    .then((res) => {
      return {
        status: 'success',
        info: res.data.user,
      }
    })
    .catch(() => {
      return {
        status: 'idle',
        info: defaultState.info,
      }
    })
  return result
})

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    ...defaultState,
    status: 'init',
  },
  reducers: {
    setLoading: (state) => {
      state.status = 'loading'
    },
    setInfo: (state, action: PayloadAction<rateauditor.api.user.IUserInfo>) => {
      state.info = action.payload
    },
    setModal: (state, action: PayloadAction<boolean>) => {
      state.showAuthModal = action.payload
    },
    login: (
      state,
      action: PayloadAction<{ token: string; user: rateauditor.api.user.IUserInfo }>
    ) => {
      state.status = 'success'
      state.showAuthModal = false
      setAuthToken(action.payload.token)
      state.info = action.payload.user
    },
    logout: () => {
      delAuthToken()
      return defaultState
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(restoreLoginStatus.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(restoreLoginStatus.fulfilled, (state, action) => {
        if (action.payload.status === 'success') {
          state.status = 'success'
          state.info = action.payload.info
        } else {
          delAuthToken()
          state.status = 'idle'
        }
      })
      .addCase(restoreLoginStatus.rejected, (state, action) => {
        delAuthToken()
        state.status = 'idle'
      })
      .addCase(reloadUserInfo.fulfilled, (state, action) => {
        state.info = action.payload.info
      })
      .addCase(reloadUserInfo.rejected, (state, action) => {
        delAuthToken()
        state.status = 'idle'
        state.info = defaultState.info
      })
  },
})

export const { setLoading, setInfo, setModal, login, logout } = userSlice.actions

export const selectUser = (state: RootState) => state.user
export const selectLoginStatus = (state: RootState) => state.user.status
export const selectUserInfo = (state: RootState) => state.user.info
export const selectAuthModalStatus = (state: RootState) => state.user.showAuthModal

export default userSlice.reducer
