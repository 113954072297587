import React from 'react';
import { Button, Card } from 'antd';
import { useSelector } from "react-redux";
import { selectUserInfo } from "../../../redux/userSlice";

export const ClaimProfileSteps = () => {
    const userInfo = useSelector(selectUserInfo);

    return (
        <div className="flex flex-col gap-4 p-4">
            <Card title="Your Information" bordered={false} style={{ marginBottom: 24, width: '100%' }}>
                <p><strong>Your Name:</strong></p>
                <p>{userInfo?.basic_profile.first_name} {userInfo?.basic_profile.last_name}</p>
                <p><strong>Your Email:</strong></p>
                <p>{userInfo?.primary_email.email}</p>
            </Card>
        </div>
    );
}
