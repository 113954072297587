import React from 'react'
import { Alert, Button, Form, Input } from 'antd'
import { LockOutlined } from '@ant-design/icons'
import { validator as passwordValidator } from '../../../../utils/PasswordValidate'

interface IAccountChangePasswordProps {
  response?: rateauditor.general.IResponse
  handleChangePassword: (value: any, onReset: () => void) => void
}

const AccountChangePassword = (props: IAccountChangePasswordProps) => {
  const { response, handleChangePassword } = props
  const [cpForm] = Form.useForm()

  const onReset = () => {
    cpForm.resetFields()
  }

  return (
    <Form
      name="changepassword"
      form={cpForm}
      initialValues={{
        current_password: '',
        password: '',
        confirm: '',
      }}
      onFinish={(value) => {
        handleChangePassword(value, onReset)
      }}
    >
      {response ? (
        response.status === 'success' ? (
          <Form.Item>
            <Alert type="success" message={response.message} showIcon />
          </Form.Item>
        ) : (
          <Form.Item>
            <Alert type="error" message={response.error?.message} showIcon />
          </Form.Item>
        )
      ) : (
        <></>
      )}
      <Form.Item
        name="current_password"
        rules={[{ required: true, message: 'Please input your current password!' }]}
        hasFeedback
      >
        <Input
          prefix={<LockOutlined className="site-form-item-icon" />}
          type="password"
          placeholder="Current Password"
        />
      </Form.Item>

      <Form.Item
        name="new_password"
        rules={[
          { required: true, message: 'Please input your new password!' },
          {validator: passwordValidator}
        ]}
        hasFeedback

      >
        <Input
          prefix={<LockOutlined className="site-form-item-icon" />}
          type="password"
          placeholder="New Password"
        />
      </Form.Item>

      <Form.Item
        name="confirm"
        dependencies={['new_password']}
        rules={[
          { required: true, message: 'Please confirm your new password!' },
          ({ getFieldValue }) => ({
            validator(rule, value) {
              if (!value || getFieldValue('new_password') === value) {
                return Promise.resolve()
              }
              return Promise.reject(new Error('The two passwords that you entered do not match!'))
            },
          }),
        ]}
        hasFeedback
      >
        <Input
          prefix={<LockOutlined className="site-form-item-icon" />}
          type="password"
          placeholder="Confirm New Password"
        />
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit">
          Change Password
        </Button>
      </Form.Item>
    </Form>
  )
}

export default AccountChangePassword
