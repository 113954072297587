import React from 'react'
import { Alert, Button, Form, Input, Row, Tooltip } from 'antd'
import { CheckCircleTwoTone, CheckOutlined, ReloadOutlined, MailOutlined } from '@ant-design/icons'

export type TResendEmailStatus = 0 | 1 | 2 | 3

interface ICurrentEmailProps {
  userInfo: rateauditor.api.user.IUserInfo
  isResending: TResendEmailStatus
  handleResendVerifyEmail: () => void
}

interface IAccountChangeEmailProps {
  userInfo: rateauditor.api.user.IUserInfo
  isResending: TResendEmailStatus
  handleResendVerifyEmail: () => void
}

const CurrentEmail = (props: ICurrentEmailProps) => {
  const { userInfo, isResending, handleResendVerifyEmail } = props
  if (!userInfo) {
    return <div>loading...</div>
  }
  return (
    <>
      <Row style={{ marginBottom: '20px' }}>
        <span
          style={{
            display: 'inline-block',
            padding: '4px 1px',
            backgroundColor: '#f2f2f2',
            borderBottom: '1px dashed #ccc',
            minWidth: '240px',
          }}
        >
          {userInfo.primary_email.email}
        </span>
        <span style={{ margin: '0 16px' }}>
          {userInfo.primary_email.is_verified ? (
            <Tooltip title="Your email is verified" color="lime" trigger={['hover', 'click']}>
              <CheckCircleTwoTone
                twoToneColor="#52c41a"
                style={{ fontSize: '20px', padding: '4px 0' }}
              />
            </Tooltip>
          ) : (
            <Tooltip
              title="Resend verification email"
              color="geekblue"
              trigger={['hover', 'click']}
            >
              <Button
                type="link"
                loading={isResending === 1}
                disabled={isResending === 2}
                icon={isResending === 2 ? <CheckOutlined /> : <ReloadOutlined />}
                onClick={handleResendVerifyEmail}
              >
                Resend verification email
              </Button>
            </Tooltip>
          )}
        </span>
      </Row>
    </>
  )
}

const AccountChangeEmail = (props: IAccountChangeEmailProps) => {
  const { userInfo, isResending, handleResendVerifyEmail } = props
  const [cmForm] = Form.useForm()

  const onReset = () => {
    cmForm.resetFields()
  }

  // const ChangeEmailForm = (props) => {
  //   return (
  //     <Form
  //       name="changeemail"
  //       form={cmForm}
  //       initialValues={{
  //         email: ''
  //       }}
  //       onFinish={value => { props.handleChangeEmail(value, onReset) }}
  //     >
  //       {
  //         props.message
  //           ? (
  //             props.message.status === 'success'
  //               ? <Form.Item><Alert type="success" message={props.message.message} showIcon /></Form.Item>
  //               : <Form.Item><Alert type="error" message={props.message.message} showIcon /></Form.Item>
  //           )
  //           : <></>
  //       }
  //       <Form.Item
  //         name="email"
  //         rules={[
  //           { required: true, message: 'Please input your account email!' },
  //           { type: 'email', message: 'The input is not valid E-mail!' }
  //         ]}
  //       >
  //         <Input prefix={<MailOutlined className="site-form-item-icon" />} placeholder="Enter your new email address here" />
  //       </Form.Item>

  //       <Form.Item>
  //         <Button
  //           type="primary"
  //           htmlType="submit"
  //         >Change Email</Button>
  //       </Form.Item>
  //     </Form>
  //   )
  // }

  return (
    <>
      <CurrentEmail
        userInfo={userInfo}
        isResending={isResending}
        handleResendVerifyEmail={handleResendVerifyEmail}
      />
      {/* <ChangeEmailForm {...props} /> */}
    </>
  )
}

export default AccountChangeEmail
