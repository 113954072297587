import React, { useState } from 'react'
import { Col, Row, Typography, notification } from 'antd'
import { MailTwoTone } from '@ant-design/icons'
// import { connect } from 'react-redux'
// ErrorStatusHandler need tobe fixed
import { user as userApi } from '@rap/api'
import ChangePassword from './components/ChangePassword'

const { Title } = Typography

interface IPrivacyTabProps {
  userInfo: rateauditor.api.user.IUserInfo
}

const PrivacyTab = (props: IPrivacyTabProps) => {
  const { userInfo } = props

  const [changePasswordMessage, setChangePasswordMessage] = useState()

  const handleChangePassword = (data: any, onReset: () => void) => {
    userApi
      .postPasswordChange(data)
      .then((res) => {
        onReset()
        // @ts-ignore
        setChangePasswordMessage({ status: res.data.status, message: 'Change Successful!' })
      })
      .catch((error) => {
        let handlePasswordWrongError = () => {
          setChangePasswordMessage({
            // @ts-ignore
            status: error.response.data.status,
            message: 'Incorrect Password!',
          })
        }
        handlePasswordWrongError = handlePasswordWrongError.bind(this)
        let handleOtherError = () => {
          setChangePasswordMessage({
            // @ts-ignore
            status: error.response.data.status,
            message: 'Oops! Failed! Something wrong.',
          })
        }
        handleOtherError = handleOtherError.bind(this)
        // props.ErrorStatusHandler(error, handlePasswordWrongError, handleOtherError, { error: { name: ['PasswordWrongError'] } })
      })
  }

  return (
    <>
      <div className="sub-content sub-content-merge">
        <Row>
          <Title level={4}>Change Password</Title>
        </Row>
        <Row>
          <Col span={24}>
            <ChangePassword
              handleChangePassword={handleChangePassword}
              response={changePasswordMessage}
            />
          </Col>
        </Row>
      </div>
    </>
  )
}

export default PrivacyTab
