import React, { useCallback, useEffect, useRef, useState } from 'react'

import { recaptcha_sitekey } from '../../config'

interface IReCAPTCHAProps {
  action: string
  theme?: string
  onChange?: (token: string) => void
}

const ReCAPTCHA = (props: IReCAPTCHAProps) => {
  const { action, theme, onChange } = props
  const [widgetId, setWidgetId] = useState()
  const [token, setToken] = useState('')
  const recaptchaRef = useRef(null)

  const onChangeToken = useCallback((token: string) => {
    setToken(token)
    onChange?.(token)
  }, [])

  const onChecked = useCallback((token: string) => {
    onChangeToken(token)
  }, [])

  const onExpire = useCallback(() => {
    onChangeToken('expire')
  }, [])

  const onError = useCallback(() => {
    onChangeToken('error')
  }, [])

  useEffect(() => {
    window.grecaptcha.enterprise.ready.call(this, () => {
      const recaptcha_widget = window.grecaptcha.enterprise.render(recaptchaRef.current, {
        sitekey: recaptcha_sitekey,
        action: action,
        theme: theme || 'light',
        callback: onChecked,
        'expired-callback': onExpire,
        'error-callback': onError,
      })
      setWidgetId(recaptcha_widget)
    })
  }, [])

  return <div ref={recaptchaRef} />
}

export default ReCAPTCHA
