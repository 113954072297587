import React from 'react'
import { Layout } from 'antd'
import { Outlet } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'

import './style.css'
import { TITLE } from '../../config'
import Header from './Header'
import Footer from './Footer'

const MainLayout = () => (
  <>
    <Helmet>
      <title>{TITLE}</title>
    </Helmet>
    <Layout>
      <Layout.Header className="bg-white shadow-md px-4">
        <Header />
      </Layout.Header>
      <Layout.Content className="container mx-auto xl:max-w-6xl">
        <div className="min-h-1/2 py-6">
          <Outlet />
        </div>
      </Layout.Content>
      <Layout.Footer className="bg-emerald-600 footer">
        <div className="container mx-auto xl:max-w-6xl">
          <Footer />
        </div>
      </Layout.Footer>
    </Layout>
  </>
)

export default MainLayout
