import React from 'react'
import { Form, Input, Button, Rate, Row, Col, Checkbox } from 'antd'

interface IRateFormProps {
  isSubmitting: boolean
  handleRateSubmit: (value: rateauditor.api.rate.IRateBasicInfo, onReset: () => void) => void
  FillValues?: rateauditor.api.rate.IRateInfo
}

const RateForm = (props: IRateFormProps) => {
  const { isSubmitting, handleRateSubmit } = props
  const [rateForm] = Form.useForm()

  const onReset = () => {
    rateForm.resetFields()
  }

  const rateLayout = {
    labelCol: { span: 8 },
  }

  const commentLayout = {
    labelCol: { span: 4 },
  }

  const tailLayout = {
    wrapperCol: { offset: 4 },
  }

  return (
    <div className="auditorRate">
      <Form
        onFinish={(value) => {
          handleRateSubmit(value, onReset)
        }}
        form={rateForm}
        initialValues={props.FillValues ? {
            knowledge: props.FillValues.knowledge,
            communication: props.FillValues.communication,
            team: props.FillValues.team,
            value: props.FillValues.value,
            quality: props.FillValues.quality,
            comment: props.FillValues.comment,
        }:{
          knowledge: 0,
          communication: 0,
          team: 0,
          value: 0,
          quality: 0,
          comment: '',
        }
      }
      >
        <Row>
          <Col xs={24} md={12}>
            <Form.Item {...rateLayout} label="Knowledge" name="knowledge">
              <Rate allowHalf />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item {...rateLayout} label="Communication" name="communication">
              <Rate allowHalf />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xs={24} md={12}>
            <Form.Item {...rateLayout} label="Team" name="team">
              <Rate allowHalf />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item {...rateLayout} label="Value" name="value">
              <Rate allowHalf />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xs={24} md={12}>
            <Form.Item {...rateLayout} label="Quality" name="quality">
              <Rate allowHalf />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item
          {...commentLayout}
          label="Your Comment:"
          name="comment"
          rules={[{ required: true, message: 'Please write something here.' }]}
        >
          <Input.TextArea />
        </Form.Item>

        <Form.Item
          {...commentLayout}
          label={<span style={{ color: '#ff4d4f' }}>*</span>}
          name="agreement"
          valuePropName="checked"
          rules={[
            {
              validator: (_, value) =>
                value
                  ? Promise.resolve()
                  : Promise.reject(
                      new Error('You must read and check this box to post your rate!')
                    ),
            },
          ]}
        >
          <Checkbox>{"I'm telling the truth and responsible for what I write."}</Checkbox>
        </Form.Item>

        <Form.Item {...tailLayout}>
          <Button type="primary" htmlType="submit" loading={isSubmitting}>
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  )
}

export default RateForm
