import React, { useEffect, useMemo, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { Card, Col, Row, notification, Divider } from 'antd'
import { useParams } from 'react-router-dom'
import { useQuery } from 'react-query'
import { auditor as auditorApi, rate as rateApi, user as userApi } from '@rap/api'

import { TITLE } from '../../../config'
import { useAppSelector } from '../../../redux/hooks'
import { selectLoginStatus, selectUserInfo } from '../../../redux/userSlice'
import { AuditorSummary, LoginForm, RateForm, RateList, VerifyForm } from '../components'
import styles from './style.module.sass'

const operationTabList = [
  {
    key: 'rates',
    tab: (
      <span>
        Rates
        {/* {' '}
        <span
          style={{
            fontSize: 14,
          }}
        >
          (9)
        </span> */}
      </span>
    ),
  },
]

const AuditorProfile = () => {
  const params = useParams()
  const [tabKey, setTabKey] = useState('rates')

  const loginStatus = useAppSelector(selectLoginStatus)
  const userInfo = useAppSelector(selectUserInfo)
  const [user, setUser] = useState<rateauditor.api.user.IUserInfo>()


  const {
    data: auditorData,
    isLoading: isLoadingAuditor,
    isSuccess: isSuccessAuditor,
  } = useQuery(
    [auditorApi.getAuditorInfo.name, params.aid],
    () => {
      return auditorApi.getAuditorInfo(params.aid!)
    },
    {
      enabled: params.aid !== undefined,
    }
  )
  const auditor = useMemo(() => {
    if (isSuccessAuditor) {
      return auditorData.data.auditor as rateauditor.api.auditor.IAuditorInfo
    }
  }, [auditorData, isSuccessAuditor])

  useEffect(() => {
    const fetchUserData = async () => {
      const res = await userApi.getCurrentUserInfo()
      if (res.data) {
        setUser(res.data.user)
      }
    }
    fetchUserData()


  })


  const isAuditor: boolean = auditor?.linked_profile === user?._id

  const structuredData ={
    "@context": "https://schema.org",
    "@type": "WebPage",
    "url": "https://www.rateauditor.org/?page=/auditor/",
    "dateModified": "2024-08-13T18:32:29+01:00",
    "priority": "0.6"
  }

  const {
    data: ratesData,
    isLoading: isLoadingRates,
    isSuccess: isSuccessRates,
  } = useQuery(
    [rateApi.getAuditorRates.name, params.aid],
    () => {
      return rateApi.getAuditorRates(params.aid!)
    },
    {
      enabled: params.aid !== undefined,
    }
  )
  const rates = useMemo(() => {
    if (isSuccessRates) {
      return ratesData.data.rates as rateauditor.api.rate.IRateInfo[]
    }
  }, [ratesData, isSuccessRates])


  const handleSubmitRate = (form: rateauditor.api.rate.IRateBasicInfo, resetForm: () => void) => {
    const rate = {
      knowledge: form.knowledge || 0,
      communication: form.communication || 0,
      team: form.team || 0,
      value: form.value || 0,
      quality: form.quality || 0,
      comment: form.comment || '',
    }
    if (auditor) {
      rateApi.postRate(auditor._id, rate).then((res) => {
        notification.success({
          message: 'Rate Submitted',
          description: 'Your rating will be displayed after review',
        })
        resetForm()
      })
    } else {
      notification.error({
        message: 'Rate Failed',
        description: 'Unknown error occurred.',
      })
    }
  }


  const renderChildrenByTabKey = (tabValue: string) => {
    if (tabValue === 'rates' && (!isAuditor)){
      return (
        <>
          <Divider orientation="left">Make a Rate</Divider>
          {loginStatus === 'success' ? (
            userInfo?.primary_email.is_verified ? (
              <RateForm isSubmitting={false} handleRateSubmit={handleSubmitRate} />
            ) : (
              <VerifyForm username={userInfo!.username} />
            )
          ) : (
            <LoginForm suffix={' to rate this auditor.'} />
          )}
          <Divider />
          {loginStatus === 'success' &&
            <RateList rates={rates} isLoading={isLoadingRates} />
          }
        </>
      )
    } else if (tabValue === 'rates' && (isAuditor)) {
        return (
            <>
            <Divider orientation="left">Your Rates</Divider>
            <RateList rates={rates} isLoading={isLoadingRates} isAuditor={isAuditor} />
            </>
        )
    }

    return null
  }

  return (
    <>
     <Helmet>
		    <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>	
		  </Helmet>
      {auditor && (
        <Helmet>
          <title>{`${auditor.first_name} ${auditor.last_name} | ${TITLE}`}</title>
        </Helmet>
      )}
      <Row gutter={24}>
        <Col xs={24} lg={7}>
          <AuditorSummary
            loading={isLoadingAuditor}
            currentAuditor={auditor}
            rates={rates}
            allowClaim
          />
        </Col>
        <Col xs={24} lg={17}>
          <Card
            style={{ width: '100%' }}
            className={styles.tabsCard}
            bordered={false}
            tabList={operationTabList}
            activeTabKey={tabKey}
            onTabChange={(_tabKey) => {
              setTabKey(_tabKey)
            }}
          >
            {renderChildrenByTabKey(tabKey)}
          </Card>
        </Col>
      </Row>
    </>
  )
}

export default AuditorProfile
