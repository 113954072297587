export const USStateList = [
  { text: 'Alabama', value: 'Alabama', label: 'Alabama' },
  { text: 'Alaska', value: 'Alaska', label: 'Alaska' },
  { text: 'Arizona', value: 'Arizona', label: 'Arizona' },
  { text: 'Arkansas', value: 'Arkansas', label: 'Arkansas' },
  { text: 'California', value: 'California', label: 'California' },
  { text: 'Colorado', value: 'Colorado', label: 'Colorado' },
  { text: 'Connecticut', value: 'Connecticut', label: 'Connecticut' },
  { text: 'Delaware', value: 'Delaware', label: 'Delaware' },
  { text: 'Florida', value: 'Florida', label: 'Florida' },
  { text: 'Georgia', value: 'Georgia', label: 'Georgia' },
  { text: 'Hawaii', value: 'Hawaii', label: 'Hawaii' },
  { text: 'Idaho', value: 'Idaho', label: 'Idaho' },
  { text: 'Illinois', value: 'Illinois', label: 'Illinois' },
  { text: 'Indiana', value: 'Indiana', label: 'Indiana' },
  { text: 'Iowa', value: 'Iowa', label: 'Iowa' },
  { text: 'Kansas', value: 'Kansas', label: 'Kansas' },
  { text: 'Kentucky', value: 'Kentucky', label: 'Kentucky' },
  { text: 'Louisiana', value: 'Louisiana', label: 'Louisiana' },
  { text: 'Maine', value: 'Maine', label: 'Maine' },
  { text: 'Maryland', value: 'Maryland', label: 'Maryland' },
  { text: 'Massachusetts', value: 'Massachusetts', label: 'Massachusetts' },
  { text: 'Michigan', value: 'Michigan', label: 'Michigan' },
  { text: 'Minnesota', value: 'Minnesota', label: 'Minnesota' },
  { text: 'Mississippi', value: 'Mississippi', label: 'Mississippi' },
  { text: 'Missouri', value: 'Missouri', label: 'Missouri' },
  { text: 'Montana', value: 'Montana', label: 'Montana' },
  { text: 'Nebraska', value: 'Nebraska', label: 'Nebraska' },
  { text: 'Nevada', value: 'Nevada', label: 'Nevada' },
  { text: 'New Hampshire', value: 'New Hampshire', label: 'New Hampshire' },
  { text: 'New Jersey', value: 'New Jersey', label: 'New Jersey' },
  { text: 'New Mexico', value: 'New Mexico', label: 'New Mexico' },
  { text: 'New York', value: 'New York', label: 'New York' },
  { text: 'North Carolina', value: 'North Carolina', label: 'North Carolina' },
  { text: 'North Dakota', value: 'North Dakota', label: 'North Dakota' },
  { text: 'Ohio', value: 'Ohio', label: 'Ohio' },
  { text: 'Oklahoma', value: 'Oklahoma', label: 'Oklahoma' },
  { text: 'Oregon', value: 'Oregon', label: 'Oregon' },
  { text: 'Pennsylvania', value: 'Pennsylvania', label: 'Pennsylvania' },
  { text: 'Rhode Island', value: 'Rhode Island', label: 'Rhode Island' },
  { text: 'South Carolina', value: 'South Carolina', label: 'South Carolina' },
  { text: 'South Dakota', value: 'South Dakota', label: 'South Dakota' },
  { text: 'Tennessee', value: 'Tennessee', label: 'Tennessee' },
  { text: 'Texas', value: 'Texas', label: 'Texas' },
  { text: 'Utah', value: 'Utah', label: 'Utah' },
  { text: 'Vermont', value: 'Vermont', label: 'Vermont' },
  { text: 'Virginia', value: 'Virginia', label: 'Virginia' },
  { text: 'Washington', value: 'Washington', label: 'Washington' },
  { text: 'West Virginia', value: 'West Virginia', label: 'West Virginia' },
  { text: 'Wisconsin', value: 'Wisconsin', label: 'Wisconsin' },
  { text: 'Wyoming', value: 'Wyoming', label: 'Wyoming' },
]

export const CAProvinceList = [
  { text: 'Alberta', value: 'Alberta', label: 'Alberta' },
  { text: 'British Columbia', value: 'British Columbia', label: 'British Columbia' },
  { text: 'Manitoba', value: 'Manitoba', label: 'Manitoba' },
  { text: 'New Brunswick', value: 'New Brunswick', label: 'New Brunswick' },
  {
    text: 'Newfoundland and Labrador',
    value: 'Newfoundland and Labrador',
    label: 'Newfoundland and Labrador',
  },
  { text: 'Nova Scotia', value: 'Nova Scotia', label: 'Nova Scotia' },
  { text: 'Ontario', value: 'Ontario', label: 'Ontario' },
  { text: 'Prince Edward Island', value: 'Prince Edward Island', label: 'Prince Edward Island' },
  { text: 'Quebec', value: 'Quebec', label: 'Quebec' },
  { text: 'Saskatchewan', value: 'Saskatchewan', label: 'Saskatchewan' },
]

export const CountryList = [
  { text: 'United States', value: 'United States', label: 'United States', children: USStateList },
  { text: 'Canada', value: 'Canada', label: 'Canada', children: CAProvinceList },
  { text: 'Israel', value: 'Israel', label: 'Israel' },
  { text: 'Ireland', value: 'Ireland', label: 'Ireland' },
  { text: 'India', value: 'India', label: 'India' },
  { text: 'China', value: 'China', label: 'China' },
  { text: 'Denmark', value: 'Denmark', label: 'Denmark' },
  { text: 'Malaysia', value: 'Malaysia', label: 'Malaysia' },
  { text: 'Argentina', value: 'Argentina', label: 'Argentina' },
  { text: 'Germany', value: 'Germany', label: 'Germany' },
  { text: 'United Kingdom', value: 'United Kingdom', label: 'United Kingdom' },
  { text: 'Singapore', value: 'Singapore', label: 'Singapore' },
  { text: 'Japan', value: 'Japan', label: 'Japan' },
  { text: 'Philippines', value: 'Philippines', label: 'Philippines' },
  { text: 'France', value: 'France', label: 'France' },
  { text: 'Netherlands', value: 'Netherlands', label: 'Netherlands' },
  { text: 'South Africa', value: 'South Africa', label: 'South Africa' },
  { text: 'Sweden', value: 'Sweden', label: 'Sweden' },
  { text: 'Norway', value: 'Norway', label: 'Norway' },
  { text: 'Australia', value: 'Australia', label: 'Australia' },
  { text: 'Spain', value: 'Spain', label: 'Spain' },
  { text: 'Italy', value: 'Italy', label: 'Italy' },
  { text: 'Korea, Republic of', value: 'Korea, Republic of', label: 'Korea, Republic of' },
  { text: 'Mexico', value: 'Mexico', label: 'Mexico' },
  { text: 'South Korea', value: 'South Korea', label: 'South Korea' },
  { text: 'Chile', value: 'Chile', label: 'Chile' },
  { text: 'Panama', value: 'Panama', label: 'Panama' },
  { text: 'Brazil', value: 'Brazil', label: 'Brazil' },
  { text: 'Bermuda', value: 'Bermuda', label: 'Bermuda' },
  { text: 'Luxembourg', value: 'Luxembourg', label: 'Luxembourg' },
  { text: 'Finland', value: 'Finland', label: 'Finland' },
  { text: 'Switzerland', value: 'Switzerland', label: 'Switzerland' },
  { text: 'Peru', value: 'Peru', label: 'Peru' },
  { text: 'Indonesia', value: 'Indonesia', label: 'Indonesia' },
  { text: 'Cayman Islands', value: 'Cayman Islands', label: 'Cayman Islands' },
  { text: 'Turkey', value: 'Turkey', label: 'Turkey' },
  { text: 'Colombia', value: 'Colombia', label: 'Colombia' },
  { text: 'Belgium', value: 'Belgium', label: 'Belgium' },
  { text: 'Greece', value: 'Greece', label: 'Greece' },
  { text: 'Russian Federation', value: 'Russian Federation', label: 'Russian Federation' },
  { text: 'Thailand', value: 'Thailand', label: 'Thailand' },
  { text: 'New Zealand', value: 'New Zealand', label: 'New Zealand' },
  { text: 'Nigeria', value: 'Nigeria', label: 'Nigeria' },
  { text: 'Pakistan', value: 'Pakistan', label: 'Pakistan' },
  { text: 'Austria', value: 'Austria', label: 'Austria' },
  { text: 'Puerto Rico', value: 'Puerto Rico', label: 'Puerto Rico' },
  { text: 'Bahrain', value: 'Bahrain', label: 'Bahrain' },
]
