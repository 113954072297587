const TOKEN_NAME = '_ra_auth_token'

interface IGetAuthTokenOption {
  bearerPrefix?: boolean
}

export const getAuthToken = (option?: IGetAuthTokenOption) => {
  let token = localStorage.getItem(TOKEN_NAME)
  if (!token) return undefined
  if (option) {
    if (option.bearerPrefix) {
      token = `Bearer ${token}`
    }
  }
  return token
}

export const setAuthToken = (token: string) => {
  localStorage.setItem(TOKEN_NAME, token)
}

export const delAuthToken = () => {
  localStorage.removeItem(TOKEN_NAME)
}
