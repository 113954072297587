import React, { Component, useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useQuery } from 'react-query'
import { Button, Card, Result } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import { user as userApi } from '@rap/api'
import { Helmet } from 'react-helmet-async';


interface IGetResultProps {
  isLoading: boolean
  isSuccess: boolean
  errorMessage?: rateauditor.general.IErrorMessage
}

const GetResult = (props: IGetResultProps) => {
  const { isLoading, isSuccess } = props
  if (isLoading) {
    return (
      <Result icon={<LoadingOutlined />} title="Processing..." subTitle="Processing your request" />
    )
  } else if (isSuccess) {
    return (
      <Result
        status="success"
        title="Successfully verified your email."
        extra={
          <Button type="primary" href="/">
            Back Home
          </Button>
        }
      />
    )
  } else {
    return (
      <Result
        status="error"
        title="Verification Failed"
        subTitle="Please request a new verification email."
        extra={
          <Button type="primary" href="/">
            Back Home
          </Button>
        }
      />
    )
  }
}

function VerifyEmail() {
  const [searchParams, setSearchParams] = useSearchParams()
  const [isLoading, setLoading] = useState(true)
  const [isSuccess, setSuccess] = useState(false)

  useEffect(() => {
    const token = searchParams.get('token')
    if (token) {
      userApi
        .getUserVerifyEmail(token)
        .then(() => {
          setLoading(false)
          setSuccess(true)
        })
        .catch(() => {
          setLoading(false)
        })
    } else {
      setLoading(false)
    }
  }, [])

  const structuredData = {
    "@context": "https://schema.org",
    "@type": "WebPage",
    "url": "https://www.rateauditor.org/?page=/verify-email",
    "dateModified": "2024-02-01T18:32:31+01:00",
    "priority": "0.8"
  }

  return (
    <>
    <Helmet>
		    <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>	
		  </Helmet>
    <Card className="cardContent" bordered={false}>
      <GetResult isLoading={isLoading} isSuccess={isSuccess} />
    </Card>
    </>
  )
}

export default VerifyEmail
