import { RawAxiosRequestHeaders } from 'axios'
import { axiosInstance } from './config'
import { getAuthToken } from './utils'

export const getRecentRates = async () => {
  return axiosInstance.get(`/recent-rates`)
}

export const getRecentVerifiedRates = async () => {
    return axiosInstance.get(`/recent-verified-rates`)

}

export const getUserRates = async (username: string) => {
  const headers: RawAxiosRequestHeaders = {}
  const token = getAuthToken({ bearerPrefix: true })
  token && (headers.authorization = token)
  const userInfo = axiosInstance.get(`/user/${username}/rates`, { headers: headers })
  return userInfo
}

export const getCurrentUserRates = async () => {
  const headers: RawAxiosRequestHeaders = {}
  const token = getAuthToken({ bearerPrefix: true })
  token && (headers.authorization = token)
  const userInfo = axiosInstance.get(`/user/self/rates`, { headers: headers })
  return userInfo
}

export const deleteRate = async (rateId: string) => {
  const headers: RawAxiosRequestHeaders = {}
  const token = getAuthToken({ bearerPrefix: true })
  token && (headers.authorization = token)
  const result = axiosInstance.delete(`/rate/${rateId}`, { headers: headers })
  return result
}

export const verifyRate = async (rateId: string) => {
    const headers: RawAxiosRequestHeaders = {}
    const token = getAuthToken({ bearerPrefix: true })
    token && (headers.authorization = token)
    const result = axiosInstance.post(`/rate/${rateId}/verify`, {}, { headers: headers })
    return result
}

export const rejectRate = async (rateId: string) => {
    const headers: RawAxiosRequestHeaders = {}
    const token = getAuthToken({ bearerPrefix: true })
    token && (headers.authorization = token)
    const result = axiosInstance.post(`/rate/${rateId}/reject`, {}, { headers: headers })
    return result

}
export const getPendingRates = async () => {
    const headers: RawAxiosRequestHeaders = {}
    const token = getAuthToken({ bearerPrefix: true })
    token && (headers.authorization = token)
    return axiosInstance.get(`/pending-rates`, { headers: headers })

}
export const getRateList = async () => {
    const headers: RawAxiosRequestHeaders = {}
    const token = getAuthToken({ bearerPrefix: true })
    token && (headers.authorization = token)
    return axiosInstance.get(`/rates`, { headers: headers })
}

export const getAuditorRates = async (id: string) => {
  const headers: RawAxiosRequestHeaders = {}
  const token = getAuthToken({ bearerPrefix: true })
  token && (headers.authorization = token)
  const userInfo = axiosInstance.get(`/auditor/${id}/rates`, { headers: headers })
  return userInfo
}

export const postRate = async (id: string, rate: rateauditor.api.rate.IRateBasicInfo) => {
  const headers: RawAxiosRequestHeaders = {}
  const token = getAuthToken({ bearerPrefix: true })
  token && (headers.authorization = token)
  const result = axiosInstance.post(`/auditor/${id}/rate`, rate, { headers: headers })
  return result
}

export const getRateById = async (id: string) => {
    const headers: RawAxiosRequestHeaders = {}
    const token = getAuthToken({ bearerPrefix: true })
    token && (headers.authorization = token)
    return axiosInstance.get(`/rate/${id}`, { headers: headers })
}

export const rateReply = async (rateId: string, reply: string) => {
    const headers: RawAxiosRequestHeaders = {};
    const token = getAuthToken({ bearerPrefix: true });
    if (token) {
        headers.authorization = token;
    }
    return axiosInstance.post(`/rate/${rateId}/reply`, { reply }, { headers });
};

export const getRateReplies = async (rateId: string) => {
    const headers: RawAxiosRequestHeaders = {};
    const token = getAuthToken({ bearerPrefix: true });
    if (token) {
        headers.authorization = token;
    }
    return axiosInstance.get(`/rate/${rateId}/replies`, { headers });
};
