import React, { useState } from 'react'
import { Col, Row, Typography, notification } from 'antd'
import { MailTwoTone } from '@ant-design/icons'
// import { connect } from 'react-redux'
// ErrorStatusHandler need tobe fixed
import { user as userApi } from '@rap/api'
import ChangeEmail, { TResendEmailStatus } from './components/ChangeEmail'

const { Title } = Typography

interface IGeneralTabProps {
  userInfo: rateauditor.api.user.IUserInfo
}

const GeneralTab = (props: IGeneralTabProps) => {
  const { userInfo } = props
  // 0 - false, 1 - true, 2 - sended, 3 - failed
  const [isResending, setResending] = useState<TResendEmailStatus>(0)
  const [changeEmailMessage, setChangeEmailMessage] = useState()

  const handleResendVerifyEmail = () => {
    setResending(1)
    userApi
      .getUserResendVerifyEmail()
      .then((res) => {
        notification.open({
          icon: <MailTwoTone />,
          message: 'Resend Success',
          description:
            'We have resend the verification email to your email address. Please check your mailbox.',
          duration: 10,
        })
        setResending(2)
      })
      .catch((error) => {
        let actionFailed = () => {
          notification.error({
            message: 'Something Wrong',
            description: 'We can not process your request at this moment. Please try again later.',
          })
          setResending(3)
        }
        actionFailed = actionFailed.bind(this)
        // props.ErrorStatusHandler(error, actionFailed)
      })
  }

  return (
    <>
      <div className="sub-content sub-content-merge">
        <Row>
          <Title level={4}>Email</Title>
        </Row>
        <Row>
          <Col span={24}>
            <ChangeEmail
              userInfo={userInfo}
              handleResendVerifyEmail={handleResendVerifyEmail}
              isResending={isResending}
              // handleChangeEmail={console.log}
              // message={changeEmailMessage}
            />
          </Col>
        </Row>
      </div>
    </>
  )
}

export default GeneralTab
