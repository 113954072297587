import React, { useCallback } from 'react'
import { Button, Menu, Dropdown, Avatar, Space } from 'antd'
import { Link } from 'react-router-dom'
import { CaretDownOutlined } from '@ant-design/icons'

interface INavbarUserProps {
    isLogin: boolean
    userInfo?: rateauditor.api.user.IUserInfo | null
    onLogout: () => void
}

const NavbarUser = (props: INavbarUserProps) => {
    const { isLogin, userInfo, onLogout } = props

    const handleLogout = useCallback(() => {
        onLogout()
    }, [onLogout])

    const userHomeLink = `/user/${userInfo?.username ?? ''}`
    const userMenu = (
        <Menu style={{ minWidth: '200px' }}>
            <Menu.Item key="user:username">
                <Link to={userHomeLink}>
                    <span className="font-semibold text-black">Welcome Back! {userInfo?.username ?? ''}</span>
                </Link>
            </Menu.Item>
            <Menu.Divider />

            <Menu.Item key="user:dashboard" className="leading-8">
                <Link to={userHomeLink}>Dashboard</Link>
            </Menu.Item>

            <Menu.Item key="user:setting" className="leading-8">
                <Link to="/account/setting">Account Setting</Link>
            </Menu.Item>
            <Menu.Divider />
            <Menu.Item key="user:logout" className="leading-8">
                <Button type="text" onClick={handleLogout} block danger>
                    Log out
                </Button>
            </Menu.Item>
        </Menu>
    )

    const getInitials = (firstname: string, lastname: string) => {
        const initials = `${firstname.charAt(0)}${lastname.charAt(0)}`
        return initials.toUpperCase()
    }

    if (isLogin && userInfo) {
        return (
            <div>
                <Dropdown overlay={userMenu} placement="bottomRight" trigger={['click']}>
                    <Space style={{ padding: '0 4px', cursor: 'pointer' }}>
                        <Avatar style={{ backgroundColor: 'green', verticalAlign: 'middle', fontSize: '16px'}} size="large">
                            {getInitials(userInfo.basic_profile.first_name, userInfo.basic_profile.last_name)}
                        </Avatar>
                        <CaretDownOutlined />
                    </Space>
                </Dropdown>
            </div>
        )
    } else {
        return (
            <div>
                <Space>
                    <Link to="/login">
                        <Button type="dashed">Log In</Button>
                    </Link>
                    <Link to="/signup">
                        <Button type="primary">Sign Up</Button>
                    </Link>
                </Space>
            </div>
        )
    }
}

export default NavbarUser
