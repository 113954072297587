import { RawAxiosRequestHeaders } from 'axios'
import { axiosInstance } from './config'
import { getAuthToken } from './utils'

export const getPopularAuditors = async () => {
  return axiosInstance.get(`/recent-popular`)
}

export const getAuditorList = async () => {
  return axiosInstance.get(`/auditors`)
}

export const getAuditorInfo = async (id: string) => {
  const headers: RawAxiosRequestHeaders = {}
  const token = getAuthToken({ bearerPrefix: true })
  token && (headers.authorization = token)
  const userInfo = axiosInstance.get(`/auditor/${id}`, { headers: headers })
  return userInfo
}

export const getPendingAuditorClaims = async () => {
  const headers: RawAxiosRequestHeaders = {}
  const token = getAuthToken({ bearerPrefix: true })
  token && (headers.authorization = token)
  return axiosInstance.get(`/pending-claims`, { headers: headers})
}

export const rejectAuditorClaim = async (id: string) => {
  const headers: RawAxiosRequestHeaders = {}
  const token = getAuthToken({ bearerPrefix: true })
  token && (headers.authorization = token)
  return axiosInstance.post(`/reject-claim/${id}`,{}, { headers: headers })
}

export const verifyAuditorClaim = async (id: string) => {
  const headers: RawAxiosRequestHeaders = {}
  const token = getAuthToken({ bearerPrefix: true })
  token && (headers.authorization = token)
  return axiosInstance.post(`/verify-claim/${id}`, {}, { headers: headers })
}

export const claimAuditorProfile = async (id: string) => {
  const headers: RawAxiosRequestHeaders = {}
  const token = getAuthToken({ bearerPrefix: true })
  token && (headers.authorization = token)
  return axiosInstance.post(`/submit-claim/${id}`, {}, { headers: headers })
}

export const addAuditor = async (auditorInfo: any)=> {
  const headers: RawAxiosRequestHeaders = {}
  const token = getAuthToken({ bearerPrefix: true })
  token && (headers.authorization = token)
  return axiosInstance.post(`/auditor`, auditorInfo, { headers: headers })
}

export const verifyAuditor = async (id: string) => {
  const headers: RawAxiosRequestHeaders = {}
  const token = getAuthToken({ bearerPrefix: true })
  token && (headers.authorization = token)
  return axiosInstance.post(`/verify-auditor/${id}`, {}, { headers: headers })
}

export const rejectAuditor = async (id: string) => {
  const headers: RawAxiosRequestHeaders = {}
  const token = getAuthToken({ bearerPrefix: true })
  token && (headers.authorization = token)
  return axiosInstance.delete(`/reject-auditor/${id}`, { headers: headers })
}

export const getVerifiedAuditors = async () => {
  const headers: RawAxiosRequestHeaders = {}
  const token = getAuthToken({ bearerPrefix: true })
  token && (headers.authorization = token)
  return axiosInstance.get(`/verified-auditors`, { headers: headers })
}

export const getPendingAuditors = async () => {
  const headers: RawAxiosRequestHeaders = {}
  const token = getAuthToken({ bearerPrefix: true })
  token && (headers.authorization = token)
  return axiosInstance.get(`/pending-auditors`, { headers: headers })
}

