import React, { Component, useCallback, useEffect, useState } from 'react'
import {useSearchParams } from 'react-router-dom'
import { Button, Result, Row, Col, Typography, Card } from 'antd'
import { user as userApi } from '@rap/api'
import { Helmet } from 'react-helmet-async';


import Loading from '../components/Loading'
import ResetPasswordForm from './resetPasswordForm'

const { Title } = Typography

interface IResetStepProps {
  isLoading: boolean
  isTokenValid: boolean
  isTokenExpired: boolean
  isSuccess: boolean
  isSubmitting: boolean
  handleResetPassword: (values: any, callback: () => void) => void
  error?: rateauditor.general.IErrorMessage
}

const ResetStep = (props: IResetStepProps) => {
  const {
    isLoading,
    isTokenValid,
    isTokenExpired,
    isSuccess,
    isSubmitting,
    handleResetPassword,
    error,
  } = props
  if (isLoading) {
    return <Loading />
  } else if (!isTokenValid) {
    setTimeout(() => {
      window.location.replace('/')
    }, 3000)
    return <p>It looks like you are in the wrong place. Redirect in 3 seconds...</p>
  } else if (isTokenExpired) {
    return (
      <Result
        status="error"
        title="The link has expired, please resend the reset request."
        extra={
          <Button type="primary" href="/">
            Back Home
          </Button>
        }
      />
    )
  } else if (isSuccess) {
    return (
      <Result
        status="success"
        title="Your password has been successfully reset."
        extra={
          <Button type="primary" href="/login">
            Log In
          </Button>
        }
      />
    )
  } else {
    return (
    <>
      <Row justify="center">
        <Title level={4}>Reset Password</Title>
      </Row>
      <Row justify="center">
        <Col xs={22} md={10}>
          <ResetPasswordForm
            isSubmitting={isSubmitting}
            error={error}
            handleResetPassword={handleResetPassword}
          />
        </Col>
      </Row>
    </>
    )
  }
}

function ResetPassword() {
  const [searchParams, setSearchParams] = useSearchParams()
  const [isSubmitting, setSubmitting] = useState(false)
  const [isValidatingToken, setValidatingToken] = useState(true)
  const [isTokenValid, setTokenValid] = useState(true)
  const [isTokenExpired, setTokenExpired] = useState(false)
  const [isSuccess, setSuccess] = useState(false)
  const [errorMessage, setErrorMessage] = useState()
  const [token, setToken] = useState<string>()

  const handleResetPassword = useCallback(
    (data: { newPassword: string }, resetForm: () => void) => {
      setSubmitting(true)
      if (token) {
        userApi
          .postUserResetPassword({ ...data, token })
          .then((res) => {
            resetForm()
            setSubmitting(false)
            setSuccess(true)
          })
          .catch((err) => {
            setSubmitting(false)
            setErrorMessage(err.response.data.error)
          })
      }
    },
    [token]
  )

  useEffect(() => {
    const token = searchParams.get('token')
    if (token) {
      userApi
        .postVerifyToken({ token, action: 'reset-password' })
        .then((res) => {
          setValidatingToken(false)
          setTokenExpired(false)
          setTokenValid(true)
          setToken(token)
        })
        .catch((err) => {
          if(err.response.data.error.name === 'TokenExpiredError')
          {
            setTokenExpired(true)
          } else if (err.response.data.error.name === 'JsonWebTokenError') {
            setTokenValid(false)
          }

          setValidatingToken(false)
          setErrorMessage(err.response.data.error)
        })
    } else {
      setValidatingToken(false)
      setTokenValid(false)
    }

  }, [])

  const structuredData = {
    "@context": "https://schema.org",
    "@type": "WebPage",
    "url": "https://www.rateauditor.org/?page=/reset-password",
    "dateModified": "2024-06-11T18:32:31+01:00",
    "priority": "0.8"
  }

  return (
    <>
    <Helmet>
		    <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>	
		  </Helmet>
    <Card className="cardContent" bordered={false}>
      <ResetStep
        isSubmitting={isSubmitting}
        isLoading={isValidatingToken}
        isTokenValid={isTokenValid}
        isTokenExpired={isTokenExpired}
        isSuccess={isSuccess}
        error={errorMessage}
        handleResetPassword={handleResetPassword}
      />
    </Card>
    </>
  )
}

export default ResetPassword