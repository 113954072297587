import React from 'react';
import { Tabs } from 'antd';
import CommentVerification from './comment_verification';
import AuditorVerification from './auditor_profile_claims';
import AuditorApproval from '../browse/components/AuditorTable/AddAduitor-AdminApproval';
// import AuditorApproval from 'apps/main-web/src/pages/browse/components/AuditorTable/AddAduitor-AdminApproval.tsx'

const { TabPane } = Tabs;

const Admin = () => {
    return (
        <Tabs defaultActiveKey="1">
            <TabPane tab="Comment Verification" key="1">
                <CommentVerification />
            </TabPane>
            <TabPane tab="Auditor Claims" key="2">
                <AuditorVerification />
            </TabPane>
            <TabPane tab="Auditor Approval" key="3">
                <AuditorApproval />
            </TabPane>
        </Tabs>
    );
};

export default Admin;
