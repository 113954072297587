import React from 'react'
import { Avatar, Card, Col, Row, Tag } from 'antd'
import { UserOutlined } from '@ant-design/icons'

import styles from './style.module.sass'

interface IProfileHeaderProps {
  user: rateauditor.api.user.IUserInfo
}

const ProfileHeader = (props: IProfileHeaderProps) => {
  const { user } = props

  return (
    <Card className="cardContent" cover={<div className={styles.headerCover} />} bordered={false}>
      <Row className={styles.headerInfo}>
        <Col>
          <div
            className={styles.userName}
          >{`${user.basic_profile.first_name} ${user.basic_profile.last_name}`}</div>
          <div>
            {user.permission_group.map(
              (groupName, index) => (
                <Tag key={groupName + index}>{`${groupName[0].toUpperCase()}${groupName.slice(
                  1
                )}`}</Tag>
              ),
              []
            )}
          </div>
        </Col>
      </Row>
    </Card>
  )
}

export default ProfileHeader
